const refreshMiniCart = async () => {
  const { data } = await window.axios.post(window.wc_add_to_cart_params.wc_ajax_url.toString().replace('%%endpoint%%', 'get_refreshed_fragments'));
  const content = data.fragments['div.widget_shopping_cart_content'];

  $('[data-wc="mini-cart"]').html(content);
  $('[data-wc="mini-cart-button"]').trigger('click');

  let totalItems = 0;

  if ($(content).find('.quantity').length > 0) {
    $(content).find('.quantity').each((i, qty) => {
      const qtyText = $(qty).text().trim();
      const qtyNumber = parseInt(qtyText);
      if (!isNaN(qtyNumber)) {
        totalItems += qtyNumber;
      }
    });
  }

  $('[data-wc="cart-count"]').text(totalItems);
};

(function ($) {
  $(function () {
    $('body').on('removed_from_cart', function () {
      refreshMiniCart();
    });

    $('body').on('submit', 'form.cart', async function (e) {
      e.preventDefault();

      const $form = $(this);
      const $btn = $form.find(':submit');
      $btn.addClass('disabled');

      let cart = { products: [] };

      for (const element of e.target.elements) {
        const { name, value, type, checked } = element;

        // When the product has variations, the product to add to the cart is the ID in the name
        if (name.startsWith('quantity[')) {
          const id = name.match(/\d+/)[0];
          const qty = parseInt(value);

          if (qty > 0) {
            cart.products.push({ product_id: id, quantity: qty });
          }
        } else if (type === 'checkbox') {
          if (!checked) {
            continue;
          }

          const cleanName = name.replace(/\[\]$/, '');

          if (!cart[cleanName]) {
            cart[cleanName] = [];
          }

          cart[cleanName].push(value);
        } else if (name) {
          cart[name] = value;
        }
      }

      if (!!cart.variation_id) {
        cart.product_id = cart.variation_id;
        delete cart.variation_id;

        if (!!cart['add-to-cart']) {
          delete cart['add-to-cart'];
        }
      } else if (!!cart['add-to-cart']) {
        cart.product_id = cart['add-to-cart'];
        delete cart['add-to-cart'];
      }

      if (!cart.products.length) {
        const qty = parseInt(cart.quantity);
        delete cart.quantity;

        if (qty > 0) {
          const tmp = { ...cart };
          delete tmp.products;

          cart = { products: [{ ...tmp, quantity: qty }] };
        }
      }

      // Enviar petición AJAX para cada producto
      await Promise.all(cart.products.map((product) => {
        const data = new FormData();

        for (const key in product) {
          // Asegúrate de que los arrays se envíen correctamente en la petición
          if (Array.isArray(product[key])) {
            product[key].forEach((item) => data.append(`${ key }[]`, item));
          } else {
            data.append(key, product[key]);
          }
        }
        return window.axios.post(window.wc_add_to_cart_params.wc_ajax_url.toString().replace('%%endpoint%%', 'add_to_cart'), data);
      }));

      await refreshMiniCart(true);

      $btn.removeClass('disabled');
    });
  });
})(window.jQuery);
